<template>
    <div>
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px; font-size: 15px;">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'subjectiveBankIndex' }">主观题库</el-breadcrumb-item>
            <el-breadcrumb-item>题目列表</el-breadcrumb-item>
        </el-breadcrumb>

        <!--题库列表-->
        <div style="padding-bottom: 30px;">
            <div v-for="(item, key) in dataList" :key="key" @click="doSubjective(item)">
                <page-main class="card">
                    <div style="font-size: 18px; color: #333; font-weight: bold">
                        {{ item.name }}
                    </div>
                    <div
                        style="line-height: 1.6; font-size: 16px; margin-top: 15px; margin-bottom: 20px; color: #222"
                        class="caseDetail">
                        {{ item.caseDetail }}
                    </div>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="20">
                            <div style="display: inline; color: #909399; font-size: 15px;">
                                <div style="display: inline;">
                                    {{ item.questionYear }} ·
                                    {{ ['综合案例', '小案例', '融合案例'][item.type] }} ·
                                    总{{ item.totalScore }}分
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4" style="text-align: right;">
                            <el-tag :type="item.isComplete ? 'success' : 'warning'">
                                {{ item.isComplete ? '已做过此题' : '未做过此题' }}
                            </el-tag>
                        </el-col>
                    </el-row>
                </page-main>
            </div>
            <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :hide-on-single-page="true"
                style="text-align: center; margin: 40px 0 20px 0;"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>

        <!-- 二维码 -->
        <!-- 答案解析 -->
        <el-dialog title="答案与解析" :visible.sync="showCode" :modal-append-to-body="false" width="80%">
            <div>
                <div style="margin-top: 20px;">
                    <el-card header="案情内容"
                             :body-style="{height: '180px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                        {{ subjectiveStatistic.bdSubjective.caseDetail }}
                    </el-card>
                </div>
                <div v-for="(currentData, key) in subjectiveStatistic.bdSubjectiveQuestionList" :key="key">
                    <div style="font-size: 18px; margin-top: 30px; margin-bottom: 20px; margin-left: 10px;">
                        第{{ key + 1 }}问：{{ currentData.questionStem }}（{{ currentData.questionScore }}分）
                    </div>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-card header="你的答案"
                                     :body-style="{height: '200px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                                {{
                                    subjectiveStatistic.subjectiveQuestionRecordList[key].bdSubjectiveQuestionRecord.studentAnswer
                                }}
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card header="参考答案"
                                     :body-style="{height: '200px', overflowY: 'scroll', lineHeight: 1.5, fontSize: '16px'}">
                                {{ currentData.suggestAnswer }}
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div style="margin-top: 20px; text-align: center">
                <router-link :to="{name: 'subjectiveSingle', query: {id: tempId}}" active-class="none">
                    <el-button type="primary" style="width: 100px;">
                        再做一次
                    </el-button>
                </router-link>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import VueQrcode from "@chenfengyuan/vue-qrcode";
import { Loading } from 'element-ui'

export default {
    name: "subjectiveList",
    // components: {VueQrcode},
    data() {
        return {
            bigPointId: '',
            pageSize: 10,
            currentPage: 1,
            total: 0,
            dataList: [],
            tempData: [],
            showCode: false,
            codeUrl: '',
            tempId: '',
            subjectiveStatistic: {
                bdSubjective: {},
                subjectiveQuestionRecordList: []
            }
        }
    },
    mounted() {
        if (this.$route.query.bigPointId) {
            this.handleCurrentChange(this.currentPage)
        } else {
            this.$router.go(-1)
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#f5f6fa')
    },
    methods: {
        // 去做单个题目
        doSubjective(item) {
            // 先判断是否已经完成了
            if (item.isComplete) {
                // 完成了，还在批改中
                // this.codeUrl = "http://member.harchinaedu.com/#/pages/subjective/subjectiveTips?id=" + item.bdSubjective.id + "&type=1"
                // this.showCode = true
                this.tempId = item.id
                // 获取单个统计信息
                let loadingInstance = Loading.service({text: '内容加载中……'});
                this.$api.get('/subjective/getBankSubjectiveRecord', {
                    params: {
                        id: item.id
                    }
                }).then(res => {
                    this.showCode = true
                    this.subjectiveStatistic = res.data

                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close()
                    })
                }).catch(err => {
                    console.log(err)
                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close()
                    })
                })
            } else {
                // 去做题
                if (this.$store.state.classInfo) {
                    this.$router.push({name: 'subjectiveSingle', query: {id: item.id}})
                } else {
                    this.$message.warning('当前班级无权限！请联系管理员开通~')
                }
            }
        },
        // 获取题目列表
        handleCurrentChange(val) {
            this.$api.get('/subjective/getSubjectiveListByBigPointId', {
                params: {
                    bigPointId: this.$route.query.bigPointId,
                    pageNum: val,
                    pageSize: this.pageSize
                }
            }).then(res => {
                this.dataList = res.data.records
                this.total = res.data.total
            })
        }
    }
}
</script>

<style scoped lang="scss">
.caseDetail {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card {
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 8px 8px #cbcdcf, 0 -2px 8px #cbcdcf;
        transition: all 0.2s;
        transform: translate3d(0, -7px, 0);
    }
}
</style>